<template>
  <ModuleView disable-breadcrumb>
    <template slot="header">
      <TabNavigator v-if="dataModel === 'all'" :tabs="topicsAndSpecialsToggleList">
        <div slot="buttons">
          <ButtonCreate
            v-if="isCreateButtonVisible && !selectable"
            route-name="themeSpecialAndSeries_new"
          />
        </div>
      </TabNavigator>
      <div class="buttons-wrapper">
        <button
          v-if="selectable"
          @click="saveData"
          class="btn btn-success text-right"
          :class="[ 'btn btn-success', { 'btn-disabled': !recordHasData }]"
          :disabled="!recordHasData"
        >
          <i class="fa fa-save "/>
          {{ $t('themeSpecialAndSeries.save_btn') }}
        </button>
      </div>
    </template>
    <!-- Special -->
    <template v-if="currentTab === 'special' || dataModel === 'special'" slot="body">
      <SpecialList :selectable="selectable" :dataModel="dataModel" @selectedRecord="setData"/>
    </template>
    <!-- Theme -->
    <template v-if="currentTab === 'theme' || dataModel === 'theme'" slot="body">
      <ThemeList :selectable="selectable" :dataModel="dataModel" @selectedRecord="setData"/>
    </template>
    <!-- Series -->
    <template v-if="currentTab === 'series' || dataModel === 'series'" slot="body">
      <SeriesList :selectable="selectable" :dataModel="dataModel" @selectedRecord="setData"/>
    </template>
  </ModuleView>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ModuleView from '@/components/ModuleView'
import PermissionService from '@/services/PermissionService'
import ButtonCreate from '@/components/shared/ButtonCreate.vue'
import TabNavigator from '@/components/TabNavigator.vue'
import SpecialList from '@/views/themeSpecialAndSeries/listComponents/SpecialList.vue'
import ThemeList from '@/views/themeSpecialAndSeries/listComponents/ThemeList.vue'
import SeriesList from '@/views/themeSpecialAndSeries/listComponents/SeriesList.vue'

export default {
  name: 'ThemeSpecialAndSeriesListView',
  props: {
    dataModel: {
      type: String,
      default: 'all' // all, special, theme, series
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ButtonCreate,
    ModuleView,
    TabNavigator,
    SpecialList,
    ThemeList,
    SeriesList
  },
  data () {
    return {
      enableSaveBtn: false,
      dataLoaded: false,
      record: {
        special: null,
        theme: null,
        series: null
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('tabNavigator', ['currentTab']),
    topicsAndSpecialsToggleList () {
      return [
        {
          id: 'special',
          title: this.$t('themeSpecialAndSeries.specials.header')
        },
        {
          id: 'theme',
          title: this.$t('themeSpecialAndSeries.theme.header')
        },
        {
          id: 'series',
          title: this.$t('themeSpecialAndSeries.series.header')
        }
      ]
    },
    recordHasData () {
      return this.record.special !== null || this.record.theme !== null || this.record.series !== null
    },
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    setData (record) {
      if (this.dataModel) {
        this.record[this.dataModel] = record[0]
      } else {
        this.record[this.currentTab] = record[0]
      }
    },
    saveData () {
      this.$emit('selectedRecord', { record: this.record, currentTab: this.dataModel ? this.dataModel : this.currentTab })
      this.$emit('saveData')
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  margin-bottom: 0.5rem ;

  button {
    margin-right: 0.5rem;
  }

  button:last-child {
    margin-right: 0;
  }
}

button.btn-disabled {
  background-color: #8b8b8b;
  &:hover {
    background-color: #8b8b8b;
  }
}

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>

<template>
  <div class="special-wrapper">
    <SeriesFilter />
    <div class="card">
      <div class="card-body">
        <Preloader v-if="callingAPI" />
        <DataTable
          :data="seriesList"
          :totalCount="seriesTotalCount"
          :page="seriesPage"
          :config="dataTableConfig"
          :hasEditPermission="isEditButtonVisible"
          :disableMultipleSelect="true"
          :selectable="selectable"
          @page-change="setPageAndGetRecords"
          @select-record="selectSeries"
        />
      </div>
    </div>
  </div>
</template>

<script>

import SeriesFilter from '@/components/filter/FilterSeries.vue'
import Preloader from '@/components/preloader/Preloader.vue'
import DataTable from '@/components/shared/DataTable.vue'
import PermissionService from '@/services/PermissionService'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ThemeList',
  props: {
    selectable: {
      type: Boolean,
      default: false
    },
    dataModel: {
      type: String,
      default: null
    }
  },
  components: {
    Preloader,
    DataTable,
    SeriesFilter
  },
  data () {
    return {
      dataLoaded: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('themeSpecialAndSeries', [
      'seriesList',
      'seriesTotalCount',
      'seriesPage'
    ]),
    ...mapGetters('tabNavigator', ['currentTab']),
    dataTableConfig () {
      const config = {
        fields: {
          id: this.$t('themeSpecialAndSeries.id'),
          name: this.$t('themeSpecialAndSeries.series.table_title')
        }
      }
      if (!this.selectable) {
        config.actions = {
          detail: 'series_detail',
          edit: 'themeSpecialAndSeries_edit'
        }
      }
      return config
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getSeries () {
      this.$store.dispatch('themeSpecialAndSeries/fetchSeriesList')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      if (this.currentTab === 'theme' || this.dataModel === 'theme') {
        this.$store.commit('themeSpecialAndSeries/SET_SERIES_PAGE', page)
      }
      this.getSeries()
    },
    selectSeries (series) {
      this.$emit('selectedRecord', series)
    }
  },
  mounted () {
    this.getSeries()
  }
}

</script>
